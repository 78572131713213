:root {
  --background-color: #3c0753;
  --border-color: #030637;
}

.container {
  text-align: center;
}

.board {
  height: 440px;
  width: 350px;
  display: flex;
  margin: auto;
}
.Block {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  min-height: 100px;
  background: #3c0753;
  border: 4px solid #030637;
  border-radius: 12px;
  display: inline-block;
  cursor: pointer;
}
.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.mark1 {
  background-image: url("../../Assets/circle.png");
  width: min-content;
  height: max-content;
  background-repeat: no-repeat;
}

.mark2 {
  background-image: url("../../Assets/cross.png");
  width: min-content;
  height: max-content;
  background-repeat: no-repeat;
  position: relative;
}
.reset {
  position: absolute;
  margin-left: 100px;
  margin-top: 400px;
  width: 150px;
  height: 60px;
  border: 2px solid #910a67;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  background: #030637;
  font-size: 26px;
  color: #910a67;
  margin-bottom: 50px;
}
.reset:hover {
  color: #030637;
  background: #910a67;
}
.winner {
  margin-top: 50px;
  color: white;
  position: absolute;
  margin-left: 90px;
  margin-top: -480px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
