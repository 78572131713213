body {
  background-color: #030637;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 400px;
  height: 400px;
  display: flex;
  border-radius: 10%;
  align-items: center;
  justify-content: center;
}
.titile {
  margin-top: 50px;
  color: #910a67;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
